import type { SmsModalData } from "@/models/sms";
import communicationService from "@/services/modules/communication";
import { defineStore } from "pinia";

interface UseSmsState {
  modalData: SmsModalData;
}

const getDefaultState = (): UseSmsState => ({
  modalData: {
    applications: [],
    from_number: "",
    templates: []
  }
});

const useSmsStore = defineStore("sms", {
  state: getDefaultState,
  actions: {
    async getApplicationSmsModalData(application_ids: string[]) {
      const data =
        await communicationService.getApplicationSmsModalData(application_ids);
      this.modalData = data;
    }
  }
});

export default useSmsStore;
